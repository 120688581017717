import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useSiteTemplate } from 'api/site';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/dateFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { linkHelper } from 'linkHelper';
import { useNavigate } from 'react-router-dom';

export default function ViewSiteTemplate() {
  const { templateId = '' } = useParams<{ templateId: string }>();
  const { data } = useSiteTemplate(templateId);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <main id="mainContent">
      <Container maxWidth="lg">
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            navigate(linkHelper.sites.templates.getLink());
          }}
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        >
          {t('back')}
        </Button>
        <Stack direction="row" justifyContent="space-between" marginBottom={3}>
          <Typography variant="h1" data-testid="domainLabel">
            {data?.data.result?.name}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(
                linkHelper.sites.create.getLink({
                  templateId: templateId,
                })
              );
            }}
            startIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            {t('new_site')}
          </Button>
        </Stack>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Card>
              <CardHeader
                title={<Typography variant="h2">{t('template_information')}</Typography>}
              />
              <CardContent>
                <Stack spacing={1}>
                  <Typography>{t('template_name')}</Typography>
                  <Typography fontWeight="bold">{data?.data.result?.name ?? '-'}</Typography>
                  <Divider />
                  <Typography>{t('notes')}</Typography>
                  <Typography fontWeight="bold">
                    <strong>{data?.data.result?.notes ?? '-'}</strong>
                  </Typography>
                  <Divider />
                  <Typography>{t('created')}</Typography>
                  <Grid item xs={12} sm={7}>
                    <strong>
                      {data?.data.result?.created_at
                        ? formatDate({
                            date: data?.data.result?.created_at,
                            dateStyle: 'short',
                            timeStyle: 'short',
                          })
                        : '-'}
                    </strong>
                  </Grid>
                  <Divider />
                  <Typography>{t('updated')}</Typography>
                  <Typography fontWeight="bold">
                    {data?.data.result?.updated_at
                      ? formatDate({
                          date: data?.data.result?.updated_at,
                          dateStyle: 'short',
                          timeStyle: 'short',
                        })
                      : '-'}
                  </Typography>
                  <Divider />
                  <Typography>{t('original_domain')}</Typography>
                  <Typography fontWeight="bold">
                    {data?.data.result?.created_from?.domain ?? '-'}
                  </Typography>
                  <Divider />
                  <Typography>{t('wordpress_version')}</Typography>
                  <Typography fontWeight="bold">
                    <strong>{data?.data.result?.config?.wordpress?.version ?? '-'}</strong>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={6}>
            <Card>
              <CardHeader title={<Typography variant="h2">{t('theme_and_plugins')}</Typography>} />
              <CardContent>
                <Stack spacing={1}>
                  <Typography>
                    {t('themes_installed_count', {
                      count: data?.data.result?.config?.wordpress?.themes?.length ?? 0,
                    })}
                  </Typography>
                  <Stack spacing={0.5}>
                    {data?.data.result?.config?.wordpress?.themes?.length
                      ? data.data.result.config.wordpress.themes.map(theme => (
                          <Typography key={theme.name} fontWeight="bold">
                            {theme.name}
                          </Typography>
                        ))
                      : '-'}
                  </Stack>
                  <Divider />
                  <Typography>
                    {t('plugins_installed_count', {
                      count: data?.data.result?.config?.wordpress?.plugins?.length ?? 0,
                    })}
                  </Typography>
                  <Stack spacing={0.5}>
                    {data?.data.result?.config?.wordpress?.plugins?.length
                      ? data.data.result.config.wordpress.plugins.map(plugin => (
                          <Typography key={plugin.name} fontWeight="bold">
                            {plugin.name}
                          </Typography>
                        ))
                      : '-'}
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
